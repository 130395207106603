import type { NextPage } from 'next';
import { useEffect } from 'react';
import { SpokeAuthView, SpkHead, useRouter, useRoutes } from '@spoke/common';

/**
 * This only exists to keep retro compatibility with /login route.
 * New generated links will point directly to /auth/method.
 */
const InvitationIndexPage: NextPage = () => {
  const router = useRouter();
  const routes = useRoutes();

  useEffect(() => {
    if (!router.isReady) return;
    router.replace({
      pathname: routes.AuthView(SpokeAuthView.MethodSelector),
      query: router.query,
    });
  }, [router, routes]);

  return <SpkHead />;
};

export default InvitationIndexPage;
